/* FLASHY */
.flashy {
  /* opacity: 0; */
  font-family:fantasy;
  font-weight: 300;
  color: orange;
  font-size: 6rem;
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }    
}
/* FLASHY */

/* UNBREAKABLE */
.unbreakable
{
  font-size: 4rem;
  animation-name: unbreak;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  max-width: fit-content;
  text-transform: uppercase;
  /* background-color: chartreuse; */
  transform-origin: 100% 50%;
}

@keyframes unbreak {
  0% {
    /* font-size: 1rem; */
    transform: scale(1,1);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    /* font-size: 4rem; */
    transform: scale(2,2);
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
/* UNBREAKABLE */

/* ACCORDIAN */
.accordian
{
  font-size: 6rem;
  animation-name: accordianate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
  text-transform: uppercase;
}

@keyframes accordianate {
  50% {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  75% {
    -webkit-transition: all .3s cubic-bezier(.190, 1.000, .220, 1.000) 2.3s;
    transition: all .3s cubic-bezier(.190, 1.000, .220, 1.000) 2.3s;
    letter-spacing: -.07em;
  }
}
/* ACCORDIAN */

/* ZOOMER */
.zoomer
{
  font-size: 6rem;
  animation-name: zoom;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
  text-transform: uppercase;
}

@keyframes zoom {
  50% {
    -webkit-transform: scale(1.5,1.5);
    -ms-transform: scale(1.5,1.5);
    transform: scale(1.5,1.5);

    opacity: 1;

    filter: alpha(opacity=100);
  }
}
/* ZOOMER */

/* SIZZLE */
.sizzle {
  animation: skew 3s infinite;
  transform: skew(20deg);
  animation-direction: alternate;
  opacity: .7;
}

@keyframes skew {
  0% {
    transform: skewX(20deg);
  }
  100% {
    transform: skewX(-20deg);
  }
}
/* SIZZLE */

/* SHAPESHIFT */
.shapeshift
{
  font-size: 4rem;
  animation-name: unbreak;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  max-width: fit-content;
  text-transform: uppercase;
  /* background-color: chartreuse; */
  transform-origin: 50% 100%;
}

@keyframes unbreak {
  0% {
    /* font-size: 1rem; */
    transform: scaleX(121);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    /* font-size: 4rem; */
    transform: scaleX(1);
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
/* SHAPESHIFT */