.sidebar {
  position: fixed;
  text-align: center;
  width: 20rem;
  height: 100vh;
  left: 0;
  top: 0;
  overflow-x: hidden;
  transition: all ease-in-out 0.2s;
  transition-property: width;
  padding-top: 16rem;
  padding-left: 0.5rem;
}