.ro-root {
  border: 1px solid #ddd;
  padding: 15px;
  margin: 10px;
  background-color: #333B3F;
  color: white;
  min-height: 90vh;
}

.ro-editor {
  position: relative;
  left: 18vw;
  border: 1px solid #ddd;
  font-size: 22px;
  padding: 2.5rem;
  margin: 0.5rem;
  margin-top: 2rem;
  min-height: 90vh;
  text-align: start;
  max-width: 70rem;
  border-radius: 1rem;
  background-color: #333B3F;
  color: white;
}