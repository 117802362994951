body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, p, a, span, button, input, section, aside, main {
  font-family: 'Roboto Slab', serif;
}

.roboto-slab {
  font-family: 'Roboto Slab', serif;
}

.roboto-slab-bold {
  font-family: 'Roboto Slab', serif;
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* hide recaptcha badge */
.grecaptcha-badge { opacity:0; }