.toolbar-container {
  padding: 1rem 0.5rem;
  position: relative;
  /* background-color: white; */
}

.dropdown-medium {
  width: 9rem;
}

.main-root {
  border: 1px solid #ddd;
  padding: 15px;
  margin: 10px;
  background-color: #333B3F;
  color: white;
  min-height: 90vh;
}

.main-editor {
  position: relative;
  left: 18vw;
  border: 1px solid #ddd;
  font-size: 16px;
  padding: 2.5rem;
  margin: 0.5rem;
  margin-top: 2rem;
  min-height: 90vh;
  text-align: start;
  max-width: 70rem;
  border-radius: 1rem;
  background-color: white;
  color: #000;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.superFancyBlockquote {
  color: rgb(249, 252, 252);
  font-family: 'Hoefler Text', Georgia, serif;
  font-style: italic;
  text-align: center;
  /* background-color: chocolate; */
}

.linked {
  color: blue;
  text-decoration: underline;
}

.highlighter {
  color: yellow;
}

.highlighter:hover {
  color: black;
}